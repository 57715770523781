import React from 'react';
import styled from 'styled-components';
import { Layout, SectionTemplate } from '../components';
import { Heading, Paragraph, Mixins } from '@cheapreats/react-ui';
import { useAboutUsImages } from '../queries/useAboutUsImages';
import Img from 'gatsby-image';

const IMAGE_NAMES = {
    CEO: 0,
    GLOBE_HANDS: 1,
    HUMANS: 2,
    KELOWNA: 3,
    SOCIALTHEORY: 4,
    TEAMWORK: 5,
};

const AboutPage = () => {
    const data = useAboutUsImages().images.nodes;
    return (
        <Layout title="About">
            <SectionTemplate
                header={
                    <>
                        <Heading type="h2" color="black" margin="auto">
                            About Us
                        </Heading>
                        <Heading type="h1" color="black" margin="auto">
                            We Believe, in YOU
                        </Heading>
                    </>
                }
            />
            <SectionTemplate
                headingText="No Investors, Just Honest Hardworking People, Like You"
                headingColor="black"
                subHeadingText="We are bootstrapped, just like you, meaning our only focus is you, now & in the future"
                subHeadingColor="black"
                headingProps={{ type: 'h6', margin: 'auto auto 30px auto' }}
                subHeadingProps={{ type: 'h2' }}
                imageContent={
                    <ImageContainer>
                        <Img
                            fluid={
                                data[IMAGE_NAMES.GLOBE_HANDS].childImageSharp
                                    .fluid
                            }
                        />
                    </ImageContainer>
                }
            />
            <SectionTemplate
                headingText="A Special Algorithm"
                headingColor="black"
                subHeadingText="We’ve developed from Social and Informational Network Theory the ultimate way to attract and engage with new customers directly to your business, how’s that for small 🙂"
                subHeadingColor="black"
                headingProps={{ type: 'h6', margin: 'auto auto 30px auto' }}
                subHeadingProps={{ type: 'h2' }}
                backgroundColor="secondaryBackground"
                reverse
                imageContent={
                    <ImageContainer>
                        <Img
                            fluid={
                                data[IMAGE_NAMES.HUMANS].childImageSharp.fluid
                            }
                        />
                    </ImageContainer>
                }
            />
            <SectionTemplate
                headingText="We already ready to serve, and have served"
                headingColor="black"
                subHeadingText="We have been around for the past 4 years serving University Vendors in the Toronto Region but due to the devasting effects of the pandemic we have relocated "
                subHeadingColor="black"
                headingProps={{ type: 'h6', margin: 'auto auto 30px auto' }}
                subHeadingProps={{ type: 'h2' }}
                backgroundColor="secondaryBackground"
                imageContent={
                    <ImageContainer>
                        <Img
                            fluid={{
                                ...data[IMAGE_NAMES.KELOWNA].childImageSharp
                                    .fluid,
                                aspectRatio: 2,
                            }}
                        />
                    </ImageContainer>
                }
            />
            <SectionTemplate
                headingText="Superior Customer Service"
                headingColor="black"
                subHeadingText="Our CEO will be on the ground ready to assist, becuase we want you to know that when your business is facing trouble, it’s our number one priority."
                subHeadingColor="black"
                headingProps={{ type: 'h6', margin: 'auto auto 30px auto' }}
                subHeadingProps={{ type: 'h2' }}
                backgroundColor="secondaryBackground"
                reverse
                imageContent={
                    <ImageContainer>
                        <Img
                            fluid={
                                data[IMAGE_NAMES.TEAMWORK].childImageSharp.fluid
                            }
                        />
                    </ImageContainer>
                }
            />
            <SectionTemplate
                headingText="Creativity & Innovation"
                headingColor="black"
                subHeadingText="We believe there is so much more innovation left in the food space. We love building with our clients on features they need for their business now & tomorrow."
                subHeadingColor="black"
                headingProps={{ type: 'h6', margin: 'auto auto 30px auto' }}
                subHeadingProps={{ type: 'h2' }}
                imageContent={
                    <ImageContainer>
                        <Img
                            fluid={
                                data[IMAGE_NAMES.SOCIALTHEORY].childImageSharp
                                    .fluid
                            }
                        />
                    </ImageContainer>
                }
            />
            {/* <SectionTemplate
                // headingText="Our Leadership Team"
                // headingColor="black"
                // subHeadingText="Ready to serve, you."
                // subHeadingColor="black"
                // headingProps={{ type: 'h6', margin: 'auto auto 30px auto' }}
                // subHeadingProps={{ type: 'h2' }}
                reverse
                imageContent={
                    <Column>
                        <CircleImage>
                            <Img
                                fluid={
                                    data[IMAGE_NAMES.CEO].childImageSharp.fluid
                                }
                            />
                        </CircleImage>
                        <Heading type="h6" color="black">
                            Ralph Maamari CEO
                        </Heading>
                    </Column>
                }
            /> */}
        </Layout>
    );
};

const Column = styled.div`
    ${Mixins.flex('column')}
    justify-content: center;
    align-items: center;
`;

const ImageContainer = styled.div`
    width: ${({ width }) => (width ? width : '60%')};
    margin: auto;
`;

const CircleImage = styled.div`
    overflow: hidden;
    margin: auto;
    border-radius: 9999px;
    width: 50%;
`;

export default AboutPage;
